
.compMenu .nav-link{

  color: #1D1B1E;
}

.compMenu .nav-link:hover{
  background-color: #dddddd;
  color: #3eaf86;
}

.compMenu .submenu div a{
  font-size: 1rem !important;
}

.compMenu .submenu .solid{
  display: block !important;
}

.compMenu .submenulink{
  margin-left: 30px;
}

.compMenu .arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}