.compDownload img{
    width: 200px;
}

.compDownload img:hover{
    opacity: 0.9;
}

.compDownload .subtitle{
    color: #211f22;
}