.compPipeResource table thead{
    background-color: #1D1B1E;
}

.compPipeResource table tr{
    font-size: 15px !important;
}

.btn-anyverse-active{
    border: 2px solid #1D1B1E;
}