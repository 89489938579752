body{
	background-color: #fff;
	color: #1D1B1E;
  font-family: "Poppins", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
}

*:focus{
  outline: none !important;
  box-shadow: none !important;
}

.border-av{ border: 1px solid #007b4d;}
.border-av-right{ border-right: 1px solid #007b4d;}
.border-av-bottom{ border-bottom: 1px solid #007b4d;}

a{ color: #1D1B1E; text-decoration: none;}
a:hover{ color: #21BA87; text-decoration: none;}

h2,h5{ color: #21BA87;}
.linkGreen a{ color: #4cbba3; }

.greenanyverse{ color: #4cbba3;}
.greenanyverse2{ color: #436c4a;}

.currentlink{ color: #49bc95!important;}

.pointer{cursor: pointer;}

.logout:hover{
  color: #c77979 !important;
  cursor: pointer;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.top-buffer { margin-top:20px; }

.grey{
  color: #8c8c8c;
}

.text-small{
  font-size: small;
}

.text-large{
  font-size: large;
}

.text-smaller{
  font-size: x-small;
}

.break-work{
  word-wrap: break-word;
  white-space: pre;
}


/* TABLE LIST */

.rdt_TableRow{
  color: #211f22 !important;
}

.rdt_TableRow:nth-child(even){
  background-color: #f5f5f5 !important;
  border: none !important;
}

.rdt_TableRow:nth-child(odd){
  background-color: #fff !important;
  border: none !important;
}

.rdt_TableHeadRow{
  background-color: #1D1B1E !important;
  color: #fff;
}

.rdt_Pagination{
  background-color: #fff !important;
  color: #1D1B1E !important;
}

/* BOOTSTRAP */

.form-control{
  background-color: #f9fafb !important;
  border: 1px solid #dddddd !important;
}

.btn-anyverse{
  background-color: #4cbba3 !important;
  border-radius: 0px;
  color: #fff;
}

.btn-anyverse:hover{
  background-color: #6dc4b1;
  color: #fff;
}

.btn-anyverse-active{
  border: 1px solid #211f22;
}



.btn-anyverse-secondary{
  background-color: #f5f5f5 !important;
  border-radius: 0px;
  color: #211f22;
}
