.compHeader{
  background-color: #1d1b1e;
}

.compHeader .logoimg{
  max-width: 200px;
}

.compHeader select{
  background-color: #1d1b1e;
}