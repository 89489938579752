.batchExecs{

}

.batchExecs .itemJob{
  width: 20px;
  height: 20px;
  padding: 3px;
  font-size: 8px;
  color:#000;
  margin: 3px;
  text-align: center;
  cursor: pointer;
}

.batchExecs .itemContainer{
  display: flex;
  flex-wrap: wrap;
}

.batchExecs .flexend{
  justify-content: end;
}

.batchExecs .item-finished{ background-color: #64d45f; }
.batchExecs .item-paused{ background-color: #d1d1d1; }
.batchExecs .item-queued{ background-color: #fcb931; }
.batchExecs .item-running{ background-color: #4fbde4; }
.batchExecs .item-waiting{ background-color: #e8adf6; }
.batchExecs .item-failed{ background-color: #ff5c5c; }

.batchExecs .text-finished{ color: #64d45f; }
.batchExecs .text-paused{ color: #d1d1d1; }
.batchExecs .text-queued{ color: #fcb931; }
.batchExecs .text-running{ color: #4fbde4; }
.batchExecs .text-waiting{ color: #e8adf6; }
.batchExecs .text-failed{ color: #ff5c5c; }

.batchExecs .item-finished-border{ border: 1px solid #64d45f; }
.batchExecs .item-paused-border{ border: 1px solid #d1d1d1; }
.batchExecs .item-queued-border{ border: 1px solid #fcb931; }
.batchExecs .item-running-border{ border: 1px solid #4fbde4; }
.batchExecs .item-waiting-border{ border: 1px solid #e8adf6; }
.batchExecs .item-failed-border{ border: 1px solid #ff5c5c; }
