.compViewHealthCheck textarea{
    font-family: 'Courier New', Courier, monospace;
}

.compViewHealthCheck .success{ font-size: 25px; color: #64d45f; }
.compViewHealthCheck .success:hover{ font-size: 25px; color: #52bb4c; }
.compViewHealthCheck .error{ font-size: 25px; color: #ff5c5c; }
.compViewHealthCheck .error:hover{ font-size: 25px; color: #e24646; }

.compViewHealthCheck .errorcode{ color: #ff5c5c; }

.compViewHealthCheck .paginator{ font-size: 25px; cursor: pointer; }